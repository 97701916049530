<template>
    <div class="question-page-content">
        <CommentItemInfo
            :questionsData="pageContainer.question"
            :isCustom="pageContainer.isCustom"
        />

        <div class="multiple-choice__page" :class="{ 'isNoAnswerFound': pageContainer.isNoAnswerFound }">
            <div class="multiple-choice__chart multiple-choice-card">
                <div class="multiple-choice-card__title">
                    {{ $t('Respondent\'s choices') }}
                </div>
                <p class="multiple-choice-card__text">
                    {{ $t('Based on all your Banks/filters applied') }}
                </p>
                <div class="multiple-choice-card__chart">
                    <OptionsDonutChart
                        :chart-data="pageContainer.chartData"
                        :is-empty="pageContainer.isNoAnswerFound"
                    />
                </div>
            </div>
            <div class="multiple-choice__details multiple-choice-card">
                <div class="multiple-choice-card__title">
                    {{ $t('Respondent\'s Details') }}
                </div>

                <div class="multiple-choice__options-list">
                    <el-collapse accordion>
                        <el-collapse-item
                            :name="item.title"
                            v-for="(item, index) in pageContainer.respondentsDetails"
                            :key="index"
                            :disabled="!item.respondentsCount"
                        >
                            <template #title>
                                <div class="options-list__header">
                                    <div class="options-list__header--title">
                                        <div class="option-percentage">
                                            <template v-if="pageContainer.isNoAnswerFound">-</template>
                                            <template v-else>
                                                {{ item.percentage }}%
                                            </template>
                                        </div>
                                        <h4>{{ index + 1 }}. {{ item.title }}</h4>
                                    </div>
                                    <div class="option-count">{{ item.respondentsCount }}</div>
                                </div>
                            </template>
                            <div class="options-list__content">
                                <div class="responded-users-list">

                                    <div class="responded-users-list__item" v-for="(answer, index) in item.answersData" :key="index">
                                        <div class="responded-users-list__name">
                                            <img :src="answer.user.icon_path" alt="">
                                            <span>
                                                <h4>{{ answer.user.full_name }}</h4>
                                                <p>{{ answer.user.job_title }}</p>
                                            </span>
                                        </div>
                                        <div class="responded-users-list__date">
                                            <div v-html="dateFormatInTimeZone(new Date(answer.date))" class="date-time-zone"></div>
                                            <div class="session-id">Session ID: {{ answer.surveyId }}</div>
                                        </div>
                                        <div class="responded-users-list__bank">
                                            <img :src="answer.bank.icon_path" alt="">
                                            <span>{{ answer.bank.name }}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { dateFormatInTimeZone } from "@/core/helpers/GlobalHelper";
import OptionsDonutChart from "@/buying-teams/shared-components/charts/OptionsDonutChart";
import {
    SpecificCustomQuestionMultipleChoice
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionMultipleChoice";
import CommentItemInfo from "@/buying-teams/pages/bank/dashboard/sections/CommentItemInfo";

export default {
    name: "PageContentMultipleChoice",
    components: {
        CommentItemInfo,
        OptionsDonutChart
    },
    props: {
        pageContainer: {
            type: [Object, SpecificCustomQuestionMultipleChoice],
            required: true,
        },
    },
    data() {
        return {
            dateFormatInTimeZone,
        }
    }
}
</script>

<style lang="scss">
.multiple-choice {
    &__page {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 12px;
        .multiple-choice-card {
            background: #FFFFFF;
            box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
            border-radius: 17px;
            padding: 26px 28px;
            &__title {
                font-weight: 700;
                font-size: 18px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
                margin-bottom: 14px;
            }
            &__text {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                display: flex;
                align-items: center;
                color: #000000;
                opacity: 0.5;
                margin-bottom: 0;
            }
            &__chart {
                margin: 0 auto;
                margin-top: 14px;
                margin-bottom: 4px;
            }
        }

        &.isNoAnswerFound {
            .option-percentage {
                filter: grayscale(1) opacity(0.4);
            }
        }
    }
    &__chart {
        width: 338px;
    }
    &__details {
        width: calc(100% - 350px);
        &.multiple-choice-card {
            padding-bottom: 4px;
        }
    }
    &__options-list {
        margin-top: 14px;
        .el-collapse {
            border: none;
        }
        .el-collapse-item__header {
            border-bottom: 2px solid rgba(0, 0, 0, 0.06);
            height: 64px;
            &.is-active {
                background: #F6F6F6;
                border-bottom: 2px solid transparent;
            }
        }
        .el-collapse-item__wrap {
            border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        }
        .el-collapse-item__content {
            padding: 0;
        }
        .el-collapse-item:last-child {
            .el-collapse-item__header, .el-collapse-item__wrap {
                border-bottom: 2px solid transparent;
            }
        }

        .el-collapse-item:nth-child(1) .option-percentage {
            background: #00117B;
        }

        .el-collapse-item:nth-child(2) .option-percentage {
            background: #2941D8;
        }

        .el-collapse-item:nth-child(3) .option-percentage {
            background: #586BDF;
        }

        .el-collapse-item:nth-child(4) .option-percentage {
            background: #9AA4E5;
        }
        .el-collapse-item.is-disabled .el-collapse-item__header {
            cursor: default;
        }

        .options-list {
            &__header {
                padding: 14px;
                width: calc(100% - 13px);
                display: flex;
                align-items: center;
                justify-content: space-between;

                .option-count {
                    background: #ECECEC;
                    border-radius: 3px;
                    padding: 3px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                }
                &--title {
                    display: flex;
                    align-items: center;
                    h4 {
                        font-weight: 800;
                        font-size: 16px;
                        line-height: 26px;
                        color: #515151;
                        margin-bottom: 0;
                        margin-left: 24px;
                    }
                }
            }
            &__content {
                .responded-users-list {
                    &__item {
                        padding: 14px;
                        display: flex;
                        align-items: center;
                        > div {
                            width: 33.33%;
                        }
                    }
                    &__name {
                        display: flex;
                        align-items: center;
                        img {
                            width: 39px;
                            height: 39px;
                            border-radius: 50%;
                            object-fit: cover;
                            margin-right: 12px;
                        }
                        span {
                            display: flex;
                            flex-direction: column;
                            h4 {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 13px;
                                color: #515151;
                                margin-bottom: 4px;
                            }
                            p {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 158.02%;
                                color: #969696;
                                margin-bottom: 0;
                            }
                        }
                    }
                    &__date {
                        display: flex;
                        justify-content: center;
                        min-width: 300px;
                        .date-time-zone {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #969696;
                        }
                        .session-id {
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #2B2B2B;
                            padding-left: 8px;
                            border-left: 1px solid #E5E5E5;
                            margin-left: 8px;
                        }
                    }
                    &__bank {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        img {
                            width: 23px;
                            height: 23px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                        span {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 158.02%;
                            color: #2B2B2B;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1440px) {
        &__options-list {
            .options-list__content .responded-users-list {
                &__item {
                    position: relative;
                    justify-content: space-between;
                    padding-bottom: 32px;
                }
                &__name, &__bank {
                    width: auto !important;
                }
                &__date {
                    width: auto !important;
                    position: absolute;
                    left: 62px;
                    bottom: 10px;
                    justify-content: flex-start;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        &__page {
            flex-direction: column;
            .multiple-choice-card {
                &__chart {
                    max-width: 300px;
                }
            }
        }
        &__chart {
            width: 100%;
        }
        &__details {
            width: 100%;
        }
    }

    @media (max-width: 600px) {
        &__options-list {
            .options-list__content .responded-users-list {
                &__item {
                    padding-bottom: 60px;
                    &:not(:last-child) {
                        border-bottom: 1px solid #0000000f;
                    }
                }
                &__bank {
                    position: absolute;
                    left: 62px;
                    bottom: 34px;
                    img {
                        width: 18px;
                        height: 18px;
                    }
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    @media (max-width: 500px) {
        &__options-list {
            .options-list__content .responded-users-list {
                &__bank {
                    left: 0;
                }
                &__date {
                    left: 0;
                }
            }
        }
    }
}
.option-percentage {
    width: 38px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00117B;
    font-weight: 700;
    font-size: 12.1818px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
}
</style>
