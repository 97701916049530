<template>
    <div class="comment-item--leasing-sales card" :class="{'isCustom': isCustom}">
        <div class="comment-item--leasing-sales-content">
            <div class="w-100">
                <div class="analysis-card">
                    <div class="analysis-card__carousel">
                        <div class="question">
                            <div class="custom-question-header" v-if="isCustom">
                                <div class="custom-question-label">
                                    <inline-svg src="/media/buying/icons/info-icon-gray.svg" width="15px" height="15px" />
                                    {{ $t('Custom Question') }}
                                </div>
                                <h1>{{ questionsData.productArea }} | {{ $t(questionsData.type) }} </h1>

                                <div class="question-id">
                                    <span>{{ $t("Question ID") }}</span>
                                    <strong>{{ questionsData.id || questionsData.question_id }}</strong>
                                </div>
                            </div>
                            <div class="comment-item--leasing-sales-header" v-else>
                                <span class="title">
                                    {{ questionsData.productArea }}
                                </span>
                            </div>
                            <hr/>
                            <div class="question-header" v-if="!isCustom">
                                <div>
                                    <span class="question-header-title">{{ $t('Question') }}</span>
                                </div>
                                <div class="question-header-comments"
                                     :class="{'empty-comments': !questionsData.commentsCount}"
                                     @click="handleScrollCommentSection">
                                    <img src="/media/buying/icons/comment-blue.svg" width="14" alt="">

                                    <span>{{ questionsData.commentsCount }}</span>
                                </div>
                            </div>
                            <div class="analysis-card__item">
                                <div class="analysis-card__item--question">
                                    {{ questionsData.question }}
                                </div>
                            </div>
                        </div>
                        <div class="analysis-card__footer" v-if="!isCustom">
                            <!--              <div class="buttons">-->
                            <!--                <navigation />-->
                            <!--              </div>-->
                            <div class="analysis-card__footer-tags">
                                <div>
                                    <span class="tags-label me-5">Tags</span>
                                    <el-tag
                                        v-for="tag in questionsData.tags"
                                        :key="tag"
                                        class="mx-1"
                                        type="info"
                                        color="#F8F8F8"
                                    >
                                        {{ tag }}
                                    </el-tag>
                                </div>
                                <div class="id-wrapper">
                                    <span class="id-label">ID</span>
                                    <span class="id-number">{{ questionsData.id }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { getStatusClass } from "@/core/helpers/GlobalHelper";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { QuestionModel } from "@/store/models/bank/CompareProductAreaModel";

export default {
    name: "CommentItemInfo",
    emits: ['scrollCommentSection'],
    components: {
        Carousel,
        Slide,
        Navigation
    },
    props: {
        questionsData: {
            type: [Object, QuestionModel]
        },
        isCustom: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            getStatusClass,
        }
    },
    methods: {
        handleScrollCommentSection() {
            if (this.questionsData.commentsCount) {
                this.$emit('scrollCommentSection');
            }
        }
    }
};
</script>

<style lang="scss">
.comment-item--leasing-sales {
    padding: 23px 32px 0;
    width: 100%;

    .custom-question-header {
        display: flex;
        align-items: center;
        gap: 20px;
        h1 {
            margin-bottom: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #434343;
        }
        .custom-question-label {
            margin-left: 0;
        }
        .question-id {
            margin-left: auto;
            color: #969696;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 158.023%;
            strong {
                color: #0A0A0A;
                margin-left: 4px;
            }
        }
    }

    &-header {
        .title {
            font-weight: 700;
            font-size: 18px;
            color: #434343;
        }
    }

    &-content {
        .analysis-card {
            background: #FFFFFF;

            &__carousel {
                .carousel__slide {
                    align-items: flex-start;
                }

                .question {
                    text-align: left;
                    margin-right: auto;
                    width: 100%;

                    &-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &-title {
                            font-weight: 400;
                            font-size: 14px;
                            color: rgba(113, 113, 113, 0.5);
                            margin-right: 12px;
                        }

                        &-level {
                            border-radius: 4px;
                            padding: 4px 8px;
                            margin-right: 4px;
                            font-weight: 700;
                            font-size: 11px;

                            &.status-danger {
                                background: rgba(226, 45, 33, 0.1);
                                color: #E22D21;
                            }

                            &.status-warning {
                                background: rgba(254, 249, 231, 0.5);;
                                color: #E6B25F;
                            }

                            &.status-success {
                                background: #DCFFEA;
                                color: #0E9B2D;
                            }
                        }

                        &-comments {
                            background: #F8F8F8;
                            border-radius: 6px;
                            padding: 15px 21px;
                            user-select: none;
                            transition: .2s ease-in-out;

                            & > span {
                                margin-left: 8px;
                            }

                            &:not(.empty-comments) {
                                cursor: pointer;

                                &:hover {
                                    background: #e0e4ff;
                                }
                            }
                        }
                    }
                }
            }

            &__item {
                width: 100%;

                &--question {
                    font-weight: 500;
                    font-size: 24px;
                    color: #2A2A2A;
                    margin-top: 16px;
                    max-width: 80%;
                    white-space: nowrap;
                    overflow: hidden;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 140px;

                    @media (max-width: 657px) {
                        height: 200px;
                    }
                }
            }

            &__footer {
                display: flex;
                align-items: center;
                position: absolute;
                left: 0;
                bottom: 0;

                .carousel {
                    &__prev,
                    &__next {
                        position: revert;
                        transform: revert;
                        background: transparent;
                        width: 32px;
                        height: 24px;
                        background-image: url("/media/buying/icons/arrow.png");
                        background-size: 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                        filter: invert(31%) sepia(28%) saturate(6745%) hue-rotate(226deg) brightness(97%) contrast(96%);

                        svg {
                            display: none;
                        }

                        top: 0;

                        &--in-active {
                            filter: invert(69%) sepia(0%) saturate(1843%) hue-rotate(142deg) brightness(90%) contrast(87%);
                        }
                    }

                    &__prev {
                        left: 0;
                        margin-right: 32px;
                    }

                    &__next {
                        right: 0;
                        transform: rotate(180deg);
                    }

                    &__pagination {
                        padding: 0 33px;
                        margin: 0;
                    }
                }
            }
        }
    }

    .analysis-card__footer {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.1);;

        .buttons {
            display: flex;
            justify-content: center;
            width: 220px;
        }

        &-tags {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            padding: 10px 24px;


            .tags-label {
                font-weight: 400;
                font-size: 14px;
                color: #757575;
                opacity: 0.5;
            }

            .el-tag {
                color: #000000;
                font-weight: 500;
                border: none;
                margin: 5px 0
            }

            .id-wrapper {
                .id-label {
                    font-weight: 400;
                    font-size: 12px;
                    color: #969696;
                    margin-right: 4px;
                }

                .id-number {
                    font-weight: 600;
                    font-size: 12px;
                    color: #0A0A0A;
                }
            }

            @media(max-width: 656px) {
                flex-direction: column;

            }
        }

    }

    &.isCustom {
        .analysis-card__item--question {
            height: auto;
            margin-bottom: 32px;
        }
    }
}

</style>
