<template>
    <div class="comment-item--customers p-0">
        <div class="select-customers">
            <div class="select-customers__header">
                {{ $t("All Customers") }}
                <span>{{ customers.length }}</span>
            </div>
            <div class="customers">
                <div
                  v-if="customers.length"
                  v-for="(customer, index) in customers"
                  :key="index"
                  class="customers-item-wrapper"
                  :class="{'is-collapsed': customer.collapsed}"
                >
                    <div class="customers-item">
                        <router-link :to="`/bank/customers/customer/${customer.id}`">
                            <div class="customers-item-info">
                                <el-avatar :src="customer.icon_path" class="avatar" />
                                <span class="name">{{ customer.name }}</span>
                            </div>
                        </router-link>
                        <div class="customers-item-values">
                            <span class="value" :title="$t('Score')" :class="getStatusClass(customer.score)">
                                {{ numberDecimalsFormat(customer.score) }}
                            </span>
                            <span class="value" :title="$t('Importance')">{{ customer.importance }}</span>
                            <span class="comments-count">
                                <img src="/media/buying/icons/comment.svg" class="me-1" alt="">
                                {{ customer.comments_count }}
                            </span>
                        </div>
                        <div class="customers-item-dropdown" @click="customer.toggleCollapsed()">
                            <inline-svg src="/media/buying/icons/arrow-black.svg" />
                        </div>
                    </div>

                    <Transition name="fade">
                        <div v-if="customer.collapsed" class="customers-item-collapsed">
                            <div
                              class="customers-item-collapsed__item"
                              v-for="(user, index) in customer.users"
                              :key="index"
                            >
                                <div class="user-name">
                                    <img :src="user.icon_path" alt="">
                                    <span>{{ user.first_name }} {{ user.last_name }}</span>
                                </div>
                                <div class="user-job-title">
                                    <inline-svg src="/media/buying/icons/business.svg" />
                                    <span>{{ user.job_title }}</span>
                                </div>
                                <div class="user-stats">
                                    <div class="user-stats-item" :class="getStatusClass(user.score)">
                                        <span>{{ $t('Score') }}</span>
                                        <strong>{{ numberDecimalsFormat(user.score) }}</strong>
                                    </div>
                                    <div class="user-stats-item">
                                        <span>{{ $t('Importance') }}</span>
                                        <strong>{{ numberDecimalsFormat(user.importance) }}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
                <div class="empty-list-state py-10 px-15" v-else>
                    <div class="empty-list-state__title mb-3">
                        {{ $t("No Customers Found") }}
                    </div>
                    <img class="mt-5" src="/media/buying/office-building.svg" alt="">

                    <div class="empty-list-state__subtitle mb-5">
                        {{ $t("Please check your filters") }}
                    </div>
                    <p>
                        {{ $t("It seems we can’t find customers who scored for this question. Usually this happens due to certain filter parameters. Please check and try again.")
                        }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { getStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import { Constants } from "@/core/config/constants";

export default {
    name: "SelectCustomers",
    props: {
        customers: Array
    },
    data: () => ({
        search: "",
        getStatusClass,
        numberDecimalsFormat,
        showTooltip: true,
        Constants
    })
};
</script>

<style lang="scss">
.comment-item--customers {
    min-height: 100%;
}

.fade-enter-active {
    transition: all 0.3s ease-out;
}

.fade-leave-active {
    transition: all 0.3s ease-in;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.select-customers {
    position: relative;

    &__header {
        padding: 34px 0 19px 23px;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #434343;

        span {
            background: #ECECEC;
            border-radius: 3px;
            padding: 3px 11px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            margin-left: 8px;
        }
    }

    .search-input {
        .el-input__inner {
            border: none;
            padding-left: 57px;

            &::placeholder {
                font-weight: 400;
                font-size: 16px;
                color: #2B2B2B;
                opacity: 0.3;
            }

        }

        &-icon {
            width: 20px;
            position: absolute;
            top: 17px;
            left: 25px;
            z-index: 1;
        }
    }

    &-all {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 16px 31px 16px 24px;
        cursor: pointer;
        border-bottom: 1px solid #0000000D;


        &--count {
            font-weight: 600;
            font-size: 16px;
            color: #2B2B2B;
        }

        &--label {
            font-weight: 500;
            font-size: 16px;
            color: #2B2B2B;
            margin-left: 17px;
        }
    }

    .avatar {
        background: transparent;
        width: 24px;
        height: 24px;
    }

    .customers {
        max-height: 780px;
        overflow-y: auto;
        padding-right: 15px;
        margin-right: -2px;
        position: relative;
        z-index: 1;
    }

    .customers-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 14px 16px 24px;
        border-top: 1px solid #0000000D;

        &.selected {
            background-color: #ECEFFE;
        }

        &-info {
            display: flex;
            flex-direction: row;
            align-items: center;

            .name {
                font-weight: 500;
                font-size: 16px;
                color: #2B2B2B;
                margin-left: 17px;
            }
        }

        .customers-item-dropdown {
            margin-left: 14px;
            cursor: pointer;
            svg {
                width: 14px;
                transform: rotate(180deg);
                transition: .3s;
                opacity: 0.7;
            }
        }

        &-values {
            margin-left: auto;
            .comments-count {
                font-weight: 600;
                font-size: 16px;
                color: #2B2B2B;
                min-width: 34px;
                display: inline-block;
            }

            .value {
                width: 56px;
                display: inline-block;
                text-align: center;
                border-radius: 2px;
                font-weight: 400;
                font-size: 14px;
                padding: 5px 10px;
                color: #515151;
                margin-right: 24px;
                font-weight: 600;

                &.status-danger {
                    font-weight: 800;
                    background: #FDF0F0;
                    color: #ED7B74;
                }

                &.status-warning {
                    font-weight: 800;
                    background: #FEF9E7;
                    color: #E6B25F;
                }

                &.status-success {
                    font-weight: 800;
                    background: #e8fee1;
                    color: #63B775;
                }
            }
        }
    }

    .customers-item-collapsed {
        border-radius: 12px;
        background: #F7F7F7;
        padding: 0 15px;
        margin-bottom: 10px;
        &__item {
            display: flex;
            align-items: center;
            padding: 12px 0;
            &:not(:last-child) {
                border-bottom: 1px solid #0000001A;
            }
            .user-name {
                color: #000;
                width: 30%;
                display: flex;
                align-items: center;
                gap: 4px;
                img {
                    width: 20px;
                    height: 20px;
                    border: 1px solid #dfdfdf;
                    border-radius: 50%;
                }
                span {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 20px);
                }
            }
            .user-job-title {
                color: #8B8B8B;
                display: flex;
                align-items: center;
                gap: 5px;
                padding-right: 15px;
                width: 30%;
                svg path {
                    fill: #8B8B8B;
                }
                span {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: calc(100% - 14px);
                }
            }
            .user-stats {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-wrap: wrap;
                gap: 14px;
                margin-left: auto;
                white-space: nowrap;
                width: 40%;
                .user-stats-item {
                    &:first-child {
                        width: 90px;
                    }
                    &:last-child {
                        width: 120px;
                    }
                    span {
                        padding-left: 14px;
                        position: relative;
                        color: #8B8B8B;
                        font-size: 12px;
                        font-weight: 500;
                        &:before {
                            content: "";
                            position: absolute;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            background: #CED4F8;
                        }
                    }
                    strong {
                        color: #2B2B2B;
                        font-size: 12px;
                        font-weight: 600;
                        margin-left: 6px;
                    }

                    &.status-success span:before {
                        background: #62cfaa;
                    }

                    &.status-danger span:before {
                        background: #ed7e77;
                    }

                    &.status-warning span:before {
                        background: #FBDC6C;
                    }
                }
            }
        }
    }

    .customers-item-wrapper {
        &:first-child .customers-item {
            border-top: none;
        }

        &.is-collapsed {
            .customers-item {
                .customers-item-dropdown svg {
                    transform: rotate(0deg);
                }
            }
        }
    }

    @media (max-width: 400px) {
        .customers-item {
            .customers-item-values {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 5px;

                .value {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (max-width: 1260px) {
    .comment-item--customers {
        margin-bottom: 25px;
    }
}
</style>
