import {
    SpecificCustomQuestionBaseContainer
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionBaseContainer";
import { UserModelMin } from "@/store/models/shared/UserModelMin";
import { BankModelMin } from "@/store/models/bank/BankModelMin";

interface chartDataInterface {
    series: number[],
    categories: string[],
}

interface respondentsDetailsMultipleChoiceInterface {
    title: string;
    percentage: number;
    respondentsCount: number;
    answersData: any[];
}

export class SpecificCustomQuestionMultipleChoice extends SpecificCustomQuestionBaseContainer {
    isNoAnswerFound: boolean = false;
    chartData: chartDataInterface = {
        series: [],
        categories: []
    };
    respondentsDetails: respondentsDetailsMultipleChoiceInterface[] = [];

    constructor(obj) {
        super(obj);

        this.setDataMultipleChoice(obj);
    }

    setDataMultipleChoice(data) {
        if (data.stats && data.stats.length) {
            this.isNoAnswerFound = false;
            data.stats.forEach(stat => {
                this.chartData.series.push(stat.percentage);
                this.chartData.categories.push(stat.option);
                let respondentsDetailsItem = new RespondentsDetailsMultipleChoiceModel(stat);
                this.respondentsDetails.push(respondentsDetailsItem);
            })
        } else {
            this.isNoAnswerFound = true;
            data.options.forEach(opt => {
                this.chartData.series.push(100 / data.options.length);
                this.chartData.categories.push(opt.label);
                let respondentsDetailsItem = new RespondentsDetailsMultipleChoiceModel(opt);
                this.respondentsDetails.push(respondentsDetailsItem);
            })
        }
    }
}

class RespondentsDetailsMultipleChoiceModel {
    title: string;
    percentage: number;
    respondentsCount: number;
    answersData: any[];

    constructor(data) {
        this.title = data.option || data.label;
        this.percentage = data.percentage | 0;
        this.respondentsCount = data.answers ? data.answers.length : 0;
        this.answersData = data.answers?.map(an => new RespondentsDetailsMultipleChoiceAnswerModel(an));
    }
}

class RespondentsDetailsMultipleChoiceAnswerModel {
    surveyId: number;
    user: UserModelMin;
    bank: BankModelMin;
    date: Date;

    constructor(data) {
        this.surveyId = data.survey_id;
        this.date = data.created_at;
        this.user = new UserModelMin(data.user);
        this.bank = new BankModelMin(data.bank);
    }
}
