<template>
    <div class="options-donut-chart" :class="{'isEmpty': isEmpty}">
        <apexchart
            type="donut"
            :options="chartOptions"
            :series="chartData.series"
            :height="chartHeight"
        ></apexchart>
    </div>
</template>

<script>
import { COLORS_FOR_OPTIONS } from "@/core/config/constants";

export default {
    name: "OptionsDonutChart",
    props: {
        chartData: Object,
        chartHeight: {
            type: String,
            default: '280px'
        },
        isEmpty: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        chartOptions() {
            return {
                chart: {
                    type: 'donut',
                },
                colors: [
                    COLORS_FOR_OPTIONS.COLOR_4,
                    COLORS_FOR_OPTIONS.COLOR_3,
                    COLORS_FOR_OPTIONS.COLOR_2,
                    COLORS_FOR_OPTIONS.COLOR_1
                ],
                labels: this.chartData.categories,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    show: false
                },
                stroke: {
                    width: 0,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '30%',
                        },
                    },
                },
                dataLabels: {
                    enabled: !this.isEmpty,
                    textAnchor: 'start',
                    style: {
                        fontWeight: 900,
                        colors: ['#fff'],
                    },
                    dropShadow: {
                        enabled: false,
                    },
                },
                tooltip: {
                    enabled: !this.isEmpty,
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.options-donut-chart {
    &.isEmpty {
        filter: grayscale(1) opacity(0.4);
    }
}
</style>
