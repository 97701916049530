<template>
    <div class="comment-item--analytics">
        <div>
            <div class="dashboard-section__title">
                {{ $t('Question Score') }}
            </div>
            <div class="card d-flex flex-column justify-content-between pb-0">
                <CircleChart
                    :labelTop="$t('Bank Score')"
                    :chartData="pageContainer.circleChartData"
                    :chartConfigs="{
                        width: 400,
                        height: 0.9 * 320,
                        valueVisible: false
                    }"
                    :values="[
                        pageContainer.circleChartData.score,
                        pageContainer.circleChartData.importance
                    ]"
                />
                <div class="chart-labels">
                    <div class="chart-labels__item item-score" :class="getStatusClass(pageContainer.question.score)">
                        <div class="chart-labels__item--value">
                            {{ pageContainer.question.score }}
                        </div>
                        <div class="chart-labels__item--label label-score"
                             :class="getStatusClass(pageContainer.question)">
                            {{ $t('Score') }}
                        </div>
                    </div>
                    <div class="chart-labels__item">
                        <div class="chart-labels__item--value">
                            {{ pageContainer.question.importance }}
                        </div>
                        <div class="chart-labels__item--label label-importance">
                            {{ $t('Importance') }}
                        </div>
                    </div>
                    <div class="chart-labels__item item-gap" :class="getGapStatusClass(pageContainer.question.gap)">
                        <div class="chart-labels__item--value">
                            {{ pageContainer.question.gap }}
                        </div>
                        <div class="chart-labels__item--label label-gap"
                             :class="getGapStatusClass(pageContainer.question.gap)">
                            {{ $t('Gap') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100">
            <div class="dashboard-section__title">
                {{ $t('Development over time') }}
            </div>
            <div class="col card development-over-time">
                <LineChart :chartData="pageContainer.statsDotChartData"/>
            </div>
        </div>
    </div>
</template>

<script>

import LineChart from "@/buying-teams/shared-components/charts/LineChart";
import { getGapStatusClass, getStatusClass } from "@/core/helpers/GlobalHelper";
import CircleChart from "@/buying-teams/shared-components/charts/CircleChart";
import { BankSpecificQuestionDataContainer } from "@/store/models/bank/BankSpecificQuestionDataContainer";

export default {
    name: "CommentItemAnalytics",
    components: {
        CircleChart,
        LineChart
    },
    props: {
        pageContainer: {
            type: [Object, BankSpecificQuestionDataContainer]
        }
    },
    data() {
        return {
            getGapStatusClass,
            getStatusClass
        }
    },
};
</script>

<style lang="scss">
.comment-item--analytics {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .development-over-time {
        height: 95%;
        display: flex;
        justify-content: center;
        padding: 24px;

        .chart-empty-state__content {
            margin: -24px;
        }
    }

    .chart-labels {
        display: flex;
        justify-content: space-between;

        &__item {
            padding-bottom: 25px;
            padding-right: 10px;
            position: relative;

            &.item-gap, &.item-score {
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    height: 4px;
                    width: 100%;
                    border-radius: 4px;
                }

                &.status-danger:after {
                    background: #E22D21;
                }

                &.status-warning:after {
                    background: #FBDC6C;
                }

                &.status-success:after {
                    background: #2BC490;
                }
            }

            &--value {
                font-weight: 700;
                font-size: 14px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
                margin-bottom: 8px;
            }

            &--label {
                font-weight: 400;
                font-size: 12.905px;
                line-height: 16px;
                text-align: center;
                color: rgb(0, 0, 0, 0.5);
                padding-left: 16px;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    width: 8.3px;
                    height: 8.3px;
                    border-radius: 50%;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.label-importance:after {
                    background: #CED4F8;
                }

                &.label-gap, &.label-score {
                    &.status-danger:after {
                        background: #FBDDDB;
                    }

                    &.status-warning:after {
                        background: #ffd498;
                    }

                    &.status-success:after {
                        background: rgba(10, 136, 45, 0.56);
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        & > .card {
            padding: 32px;
        }
    }
}

@media (max-width: 500px) {
    .comment-item--analytics {
        overflow: hidden;

        .ring {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
