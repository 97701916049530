<template>
    <CustomLoader v-if="isSorting"/>
    <div v-else class="comment-item--customers-selected">
        <div class="comment-item--customers-selected-header">
            <span class="count">
                {{ $t("All Comments") }}
                <span>{{ allComments.length }}</span>
            </span>

            <el-select v-model="selectedLevel" placeholder="Select" class="sort-select">
                <el-option
                    v-for="item in scoresLevels"
                    :key="item"
                    :label="item.label"
                    :value="item.key"
                />
            </el-select>
        </div>
        <div v-if="allComments.length" class="comment-item--customers-selected-body">
            <div v-for="(comment, index) in allComments" :key="index">
                <CommentItem :comment="comment"/>
            </div>
        </div>
        <div v-else>
            <div class="empty-list-state py-10 px-15">
                <div class="empty-list-state__title mb-3">
                    {{ $t("No Comments") }}
                </div>
                <img class="mt-5" height="98" src="/media/buying/icons/no-message.svg" alt="">

                <div class="empty-list-state__subtitle mb-5">
                    {{ $t("No comments was made on this question") }}
                </div>
                <p>{{ $t('You will see the customers who made comments in the list on your left. You can select and see their comments once made.') }}</p>
            </div>
        </div>
    </div>
</template>

<script>

import CommentItem from "@/buying-teams/pages/bank/dashboard/components/CommentItem";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { scoresSortingLevels } from "@/buying-teams/pages/bank/dashboard/dashboardData";
import { BankSpecificQuestionDataContainer } from "@/store/models/bank/BankSpecificQuestionDataContainer";

export default {
    name: "CommentItemCustomersSelected",
    components: {CustomLoader, CommentItem},
    props: {
        allComments: Array,
        pageContainer: {
            type: [Object, BankSpecificQuestionDataContainer]
        }
    },
    data() {
        return {
            selectedLevel: "highest_score",
            isSorting: false
        };
    },
    watch: {
        selectedLevel: {
            async handler(newVal) {
                this.isSorting = true;
                await this.pageContainer.sortCommentsBy(newVal);
                this.isSorting = false;
            }
        }

    },
    computed: {
        scoresLevels() {
            return scoresSortingLevels;
        }
    },
    created() {
        this.pageContainer.sortCommentsBy(this.selectedLevel);
    }
};
</script>

<style lang="scss">
.comment-item--customers-selected {
    &-header {
        padding: 26px 23px 13px 30px;
        display: flex;
        align-items: center;

        .avatars-group {
            .avatar {
                width: 33px;
                height: 33px;
                margin-left: -12px;
                border: 2px solid #FFFFFF;
            }
        }

        .more {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #435BF4;
            border: 4px solid #FFFFFF;
            border-radius: 50%;
            margin-left: -12px;
            width: 33px;
            height: 33px;
            font-weight: 700;
            font-size: 12px;
            color: #FFFFFF;
        }

        .count {
            margin-left: 12px;
            font-weight: 700;
            font-size: 18px;
            color: #434343;
            padding-right: 2px;

            span {
                background: #ECECEC;
                border-radius: 3px;
                padding: 3px 11px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                margin-left: 8px;
            }
        }

        .el-select {
            margin-left: auto;

            .el-input__inner {
                background: #F4F4F4;
                border: 1px solid #435BF4;
                border-radius: 27px;
                height: 35px;
            }

            .el-input__inner::placeholder {
                color: #313131;
            }
        }

        @media (max-width: 991px) {
            padding: 10px;
        }
    }

    &-body {
        margin-top: 34px;
        padding-bottom: 2px;
        max-height: 780px;
        overflow-y: auto;
    }
}

@media (max-width: 1260px) {
    .comment-item {
        &--customers-selected-header {
            padding-left: 23px;

            .count {
                margin-left: 0;
            }
        }

        &--customers-selected-body {
            .comment-item {
                padding-left: 20px;
            }
        }
    }
}

@media (max-width: 500px) {
    .comment-item {
        &--customers-selected-header {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .el-select {
                margin-left: 0;
                margin-top: 15px;
            }
        }

        &--customers-selected-body {
            .comment-item-content {
                &--header {
                    flex-direction: column;
                    align-items: flex-start;

                    .name {
                        margin-bottom: 10px;
                    }

                    .info {
                        flex-direction: column;
                        width: 100%;

                        .info-item {
                            border-right: none;
                            padding-left: 0;
                            padding-bottom: 5px;
                            padding-top: 5px;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.0509803922);

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }

                &--body {
                    margin-left: -71px;
                }

                &--footer {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-left: -71px;

                    .question-info-item {
                        border-bottom: none;
                        border-left: 4px solid;

                        &.question-info-label--importance {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}
</style>
