<template>
    <div class="comment-item-bottom__info--bottom card">
        <div class="chart-info__customers chart-info-item">
            <img src="/media/buying/icons/office-building.svg" alt="office-building">
            <div class="chart-info--label">{{ $t("Customers") }}</div>
            <div class="chart-info--value">{{ pageContainer.scoreSectionData.customers }}</div>
            <!--      <div class="chart-info&#45;&#45;score green-type">-->
            <!--        <span>4.5%</span>-->
            <!--        <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--          <path-->
            <!--            d="M2.83159 0.890084C3.12846 0.290198 3.98401 0.290199 4.28087 0.890085L6.82975 6.04066C7.09568 6.57804 6.70469 7.20778 6.1051 7.20778H1.00736C0.407773 7.20778 0.0167809 6.57804 0.282718 6.04066L2.83159 0.890084Z" />-->
            <!--        </svg>-->
            <!--      </div>-->
        </div>
        <div class="chart-info__respondents chart-info-item">
            <img src="/media/buying/icons/share-user.svg" alt="share-user">
            <div class="chart-info--label">{{ $t("Respondents") }}</div>
            <div class="chart-info--value">{{ pageContainer.scoreSectionData.respondents }}</div>
            <!--        <div class="chart-info&#45;&#45;score red-type">-->
            <!--          <span>4.5%</span>-->
            <!--          <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--            <path-->
            <!--              d="M2.83159 0.890084C3.12846 0.290198 3.98401 0.290199 4.28087 0.890085L6.82975 6.04066C7.09568 6.57804 6.70469 7.20778 6.1051 7.20778H1.00736C0.407773 7.20778 0.0167809 6.57804 0.282718 6.04066L2.83159 0.890084Z" />-->
            <!--          </svg>-->
            <!--      </div>-->
        </div>
        <div class="chart-info__comments chart-info-item">
            <img src="/media/buying/icons/general/gen063.svg" alt="comments">
            <div class="chart-info--label">{{ $t("Comments") }}</div>
            <div class="chart-info--value">{{ pageContainer.scoreSectionData.comments }}</div>
        </div>
    </div>
</template>

<script>

import { BankSpecificQuestionDataContainer } from "@/store/models/bank/BankSpecificQuestionDataContainer";

export default {
    name: "CommentItemCustomersRespondents",
    props: {
        pageContainer: {
            type: [Object, BankSpecificQuestionDataContainer]
        }
    }
};
</script>

<style scoped lang="scss">
.comment-item-bottom__info--bottom {
    display: flex;
    flex-direction: row;

    &.card {
        padding: 0;
    }
}

.comment-item-bottom {

    &__info {
        &--bottom {
            display: flex;

            .chart-info {
                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    padding: 30px 0;
                }

                &__respondents {
                    display: flex;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    border-left: 1px solid rgba(0, 0, 0, 0.1);
                }

                &--label {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 13px;
                    color: #9CA0AB;
                    margin-left: 14px;
                }

                &--value {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 13px;
                    color: rgba(0, 0, 0, 0.87);
                    margin-left: 14px;
                }

                &--score {
                    margin-left: 8px;
                    display: flex;
                    align-items: center;

                    span {
                        font-weight: 500;
                        font-size: 11.3193px;
                        line-height: 14px;
                        margin-right: 4px;
                    }

                    &.green-type {
                        span {
                            color: #1B9F72;
                        }

                        svg {
                            fill: #2BC490;
                        }
                    }

                    &.red-type {
                        span {
                            color: #E22D21FF;
                        }

                        svg {
                            fill: #E22D21;
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            @media (max-width: 898px) {
                flex-direction: column;
                .chart-info__respondents {
                    border-left: none;
                    border-right: none;
                }
                .chart-info-item {

                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }
}
</style>
