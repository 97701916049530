<template>
  <div class="comment-item">
    <div>
      <el-avatar :src="comment.user_icon_path" class="avatar" />
    </div>
    <div class="comment-item-content">
      <div class="comment-item-content--header">
        <span class="name">
          {{ comment.fullname }}
        </span>
        <div class="info">
          <div class="info-item">
            <img class="info-item-icon" width="15" height="15" :src="comment.business_icon_path">
            <span class="info-item-text">{{ comment.business_name }}</span>
          </div>
          <div class="info-item">
            <span class="info-item-text">{{ comment.job_title }}</span>
          </div>
          <div class="info-item">
            <span class="info-item-text">{{ moment(comment.date).format('DD.MM.YYYY') }}</span>
          </div>
        </div>
      </div>
      <div class="comment-item-content--body">
        {{ comment.comment }}
      </div>
      <div class="comment-item-content--footer">
        <div v-for="item in scoreData" :class="`${item.statusClass} ${getLabelClass(item)} question-info-item`">

          <div class="question-info-item--content">
            <div class="question-info-label">
              <span :class="`${getLabelClass(item)}`">
                {{ item.name }}
              </span>
            </div>
            <div class="question-info-item--header">
              <span class="title">
              {{ numberDecimalsFormat(item.value) }}
              </span>
            </div>
          </div>
        </div>
<!--        <span v-if="customer.deleted" class="status-deleted">-->
<!--          {{ $t("This user was deleted") }}-->
<!--        </span>-->
        <span v-if="comment.country" class="status-bold">
          {{ comment.country }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getStatusClass, getGapStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export default {
  name: "CommentItem",
  props: {
    selected: Array,
    customer: Object,
    comment: Object
  },
  data() {
    return {
      moment,
      getStatusClass,
      numberDecimalsFormat
    };
  },
  methods: {
    getLabelClass(data) {
      switch (data.name) {
        case "Score":
          return "question-info-label--score";
          break;
        case "Importance":
          return "question-info-label--importance";
          break;
        default:
          return "question-info-label--gap";
      }
    },
    getBottomLineClass(data) {
      if (data.name === "Importance") {
        return "";
      }

      if (data.value > 0 && data.value < 0.5) {
        return "yellow-border";
      }

      if (data.value > 1) {
        return "green-border";
      }
      return "red-border";
    }
  },
  computed: {
    scoreData() {
      return [
        { name: "Score", value: this.comment.score, statusClass: getStatusClass(this.comment.score) },
        { name: "Importance", value: this.comment.importance, statusClass: '' },
        { name: "GAP", value: this.comment.gap, statusClass: getGapStatusClass(this.comment.gap) }
      ]
    }
  }
};

</script>

<style scoped lang="scss">
.comment-item {
  display: flex;
  padding-top: 26px;

  .avatar {
    width: 51px;
    height: 51px;
    margin-right: 20px;
  }

  &-content {
    width: calc(100% - 71px);
    border-bottom: 1px solid #F2F2F2;

    &--header {
      display: flex;
      align-items: center;

      .name {
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        margin-right: 4px;
      }

      .info {
        display: flex;

        &-item {
          border-right: 1px solid #0000000D;
          padding: 0 10px;

          &:last-child {
            border-right: none;
          }

          &-text {
            font-weight: 400;
            font-size: 12px;
            color: #969696;
          }

          &-icon {
            margin-right: 10px;
          }
        }
      }
    }

    &--body {
      font-weight: 400;
      font-size: 16px;
      color: #757575;
      margin: 11px 0 20px;
      max-width: 90%;
    }

    &--footer {
      display: flex;
      align-items: center;
      margin-bottom: -2px;

      .question-info-item {
        display: flex;
        align-items: center;
        padding: 0 20px 15px;
        border-bottom: 4px solid;

        &.status-danger {
          border-color: #E22D21;
          .question-info-label--gap:after {
            background: #ffb2ad;
          }
        }

        &.status-warning {
          border-color: #FBDC6C;
        }

        &.status-success {
          border-color: #2BC490;
          .question-info-label--gap:after {
            background: #8fedcb;
          }
        }

        &.question-info-label--importance {
          border-bottom: none;
        }

        &--header {
          display: flex;
          align-items: center;

          .title {
            font-weight: 700;
            font-size: 16px;
            color: #000000;
            opacity: 0.87;
            margin-right: 4px;
          }

          .green-type {
            font-size: 10px;

            span {
              color: #1B9F72;
            }
          }

          .red-type {
            span {
              color: #E22D21FF;
            }
          }
        }

        &--content {
          display: flex;

          .question-info-label {
            margin-right: 4px;
            display: flex;
            align-items: center;

            h4 {
              font-weight: 700;
              font-size: 14px;
              line-height: 13px;
              color: #000000B2;
            }

            span {
              font-weight: 400;
              font-size: 12.905px;
              line-height: 16px;
              text-align: center;
              color: #0000007F;
              padding-left: 16px;
              position: relative;

              &:after {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }

            &--score:after {
              background: #435BF4;
            }

            &--importance:after {
              background: #CED4F8;
            }

            &--gap:after {
              background: #2BC490;
            }
          }
        }
      }

      .status-deleted {
        font-weight: 400;
        font-size: 12px;
        color: #E22D21;
        background: rgba(226, 45, 33, 0.05);
        border-radius: 5px;
        padding: 7px;
        margin-left: auto;
      }

        .status-bold {
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: #ECECEC;
            color: #000;
            font-family: Inter;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-left: auto;
            margin-right: 30px;
        }

      @media(max-width: 1575px) {
        .status-deleted {
          margin-left: unset;
        }
      }
    }
  }
}
</style>

