<template>
    <div class="question-page-content">
        <CommentItemInfo
            :questionsData="pageContainer.question"
            :isCustom="pageContainer.isCustom"
        />

        <div class="text-only__page">
            <div class="text-only__page--title">
                {{ $t('All Answers') }}
                <span>{{ pageContainer.allAnswers.length }}</span>
            </div>

            <div class="answers-content" v-if="pageContainer.allAnswers.length">
                <div class="answers-content__item" v-for="(answer, index) in pageContainer.displayData" :key="index">
                    <div class="user-image">
                        <img :src="answer.user.icon_path" alt="">
                    </div>
                    <div class="answers-info">
                        <div class="answers-info__header">
                            <div class="user-name">{{ answer.user.full_name }}</div>
                            <div class="user-position">{{ answer.user.job_title }}</div>
                            <div v-html="dateFormatInTimeZone(new Date(answer.date))" class="date-time-zone"></div>
                            <div class="session-id">Session ID: {{ answer.surveyId }}</div>
                        </div>
                        <div class="answers-info__text">{{ answer.answerText }}</div>
                        <div class="answers-info__footer">
                            <span>{{ $t('Text Answer for') }}</span>
                            <div class="footer-bank">
                                <img :src="answer.bank.icon_path" alt="">
                                <strong>{{ answer.bank.name }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DashboardEmptyState
                v-else
                class-name="mb-12"
                icon="/media/buying/icons/no-text-answers-found.svg"
                :title="$t('No Answers Found')"
                text="Lorem ipsum dolor sit amet consectetur lorem ipsum dolor sit amet consectetur"
            />
        </div>

        <CustomPagination
            v-if="pageContainer.allAnswers.length"
            class="mt-3"
            :current-page="pageContainer.pagination.page"
            :total="pageContainer.pagination.totalItems"
            :page-size="pageContainer.pagination.pageSize"
            :hide-on-single-page="false"
            @currentPageChange="handleCurrentChange"
            @pageSizeChange="handlePageSizeChange"
        />
    </div>
</template>

<script>
import { dateFormatInTimeZone } from "@/core/helpers/GlobalHelper";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import { SpecificCustomQuestionTextOnly } from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionTextOnly";
import CommentItemInfo from "@/buying-teams/pages/bank/dashboard/sections/CommentItemInfo";

export default {
    name: "PageContentTextOnly",
    components: {
        CommentItemInfo,
        DashboardEmptyState,
        CustomPagination,
    },
    props: {
        pageContainer: {
            type: [Object, SpecificCustomQuestionTextOnly],
            required: true,
        },
    },
    data() {
        return {
            dateFormatInTimeZone
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.pageContainer.pagination.page = val;
        },
        handlePageSizeChange(size) {
            this.pageContainer.pagination.pageSize = size
        }
    }
}
</script>

<style scoped lang="scss">
.text-only {
    &__page {
        background: #FFFFFF;
        box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
        border-radius: 17px;
        width: 100%;
        &--title {
            padding: 20px 24px;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #434343;
            display: flex;
            align-items: center;
            span {
                background: #ECECEC;
                border-radius: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                padding: 3px 5px;
                margin-left: 10px;
            }
        }

        .answers-content {
            width: 100%;
            max-height: 580px;
            overflow-y: auto;
            &__item {
                padding: 17px 24px 0 24px;
                display: flex;
                .user-image {
                    img {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                .answers-info {
                    width: calc(100% - 50px);
                    padding-left: 16px;
                    &__header {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin-bottom: 22px;
                        .user-name {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 13px;
                            color: #515151;
                            margin-right: 16px;
                        }
                        .user-position {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #969696;
                            padding-right: 16px;
                            margin-right: 16px;
                            border-right: 1px solid #E5E5E5;
                        }
                        .date-time-zone {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #969696;
                        }
                        .session-id {
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #2B2B2B;
                            margin-left: auto;
                        }
                    }
                    &__text {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        color: #757575;
                        margin-bottom: 12px;
                    }
                    &__footer {
                        display: flex;
                        align-items: center;
                        padding-bottom: 16px;
                        span {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #969696;
                            margin-right: 15px;
                        }
                        .footer-bank {
                            background: #ECECEC;
                            border-radius: 4px;
                            padding: 2px 8px;
                            img {
                                width: 12px;
                                height: 12px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 50%;
                                object-fit: cover;
                                margin-right: 10px;
                            }
                            strong {
                                font-weight: 700;
                                font-size: 11px;
                                line-height: 13px;
                                color: #000000;
                            }
                        }
                    }
                }

                &:not(:last-child) {
                    .answers-info {
                        &__footer {
                            border-bottom: 1px solid #F2F2F2;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        &__page {
            .answers-content {
                &__item {
                    .answers-info {
                        position: relative;
                        .session-id {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
</style>
