<template>
    <div class="question-page-content page-content-multi-choice-grid">
        <CommentItemInfo
            :questionsData="pageContainer.question"
            :isCustom="pageContainer.isCustom"
        />

        <div class="reply-graph">
            <div class="reply-graph__title">
                {{ $t('Reply graph') }}
            </div>

            <div class="reply-graph__chart">
                <OptionsBarChart :chart-data="pageContainer.chartData" />
            </div>
        </div>

        <div class="options-tabs">
            <el-tabs v-model="pageContainer.activeTabName" class="demo-tabs">
                <el-tab-pane
                    v-for="(row, rowIndex) in pageContainer.respondentsDetails"
                    :key="rowIndex"
                    :name="row.rowName"
                >
                    <template #label>
                        <div class="options-tabs-item">
                            <strong>{{ rowIndex + 1 }}. {{ row.rowName }}</strong>
                            <span>{{ row.overallAnswersCount }}</span>
                        </div>
                    </template>

                    <div class="options-tabs-content">
                        <el-collapse accordion>
                            <el-collapse-item
                                :name="column.title"
                                v-for="(column, columnIndex) in row.columnsData"
                                :key="columnIndex"
                                :disabled="!column.respondentsCount"
                            >
                                <template #title>
                                    <div class="options-tabs-content__header">
                                        <div class="options-tabs-content__header--title">
                                            <div class="option-percentage">
                                                <template v-if="!pageContainer.isNoAnswerFound">
                                                    {{ column.percentage }}%
                                                </template>
                                            </div>
                                            <h4>{{ columnIndex + 1 }}. {{ column.title }}</h4>
                                        </div>
                                        <div class="option-count">{{ column.respondentsCount }}</div>
                                    </div>
                                </template>
                                <div class="options-tabs-content__data">
                                    <div class="content-data-item" v-for="(answer, answerIndex) in column.answersData" :key="answerIndex">
                                        <div class="content-data-item__name">
                                            <img :src="answer.user.icon_path" alt="">
                                            <span>{{ answer.user.full_name }}</span>
                                        </div>
                                        <div class="content-data-item__date">
                                            <div class="job-title">{{ answer.user.job_title }}</div>
                                            <div v-html="dateFormatInTimeZone(new Date(answer.date))" class="date-time-zone"></div>
                                            <div class="session-id">Session ID: {{ answer.surveyId }}</div>
                                        </div>
                                        <div class="content-data-item__bank">
                                            <img :src="answer.bank.icon_path" alt="">
                                            <span>{{ answer.bank.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import OptionsBarChart from "@/buying-teams/shared-components/charts/OptionsBarChart";
import { dateFormatInTimeZone } from "@/core/helpers/GlobalHelper";
import {
    SpecificCustomQuestionMultiChoiceGrid
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionMultiChoiceGrid";
import CommentItemInfo from "@/buying-teams/pages/bank/dashboard/sections/CommentItemInfo";

export default {
    name: "PageContentMultiChoiceGrid",
    components: {
        CommentItemInfo,
        OptionsBarChart,
    },
    props: {
        pageContainer: {
            type: [Object, SpecificCustomQuestionMultiChoiceGrid],
            required: true,
        },
    },
    data() {
        return {
            dateFormatInTimeZone,
        }
    },

}
</script>

<style lang="scss">
.page-content-multi-choice-grid {
    .reply-graph {
        background: #FFFFFF;
        box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
        border-radius: 17px;
        padding: 29px 24px;
        width: 100%;
        &__title {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #434343;
        }
        &__chart {
            margin-top: 15px;
            width: 100%;
        }
    }
    .options-tabs {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
        border-radius: 17px;
        overflow: hidden;
        .el-tabs {
            &__header {
                margin-bottom: 0;
            }
            &__nav-wrap {
                padding: 26px 26px 0 26px;
            }
            .el-tabs__item.is-active {
                .options-tabs-item strong {
                    color: #435BF4;
                }
            }
        }
        .options-tabs-item {
            display: flex;
            align-items: center;
            gap: 12px;
            strong {
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.1px;
                color: #2A2A2A;
            }
            span {
                background: #ECECEC;
                border-radius: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .options-tabs-content {
            .el-collapse {
                border: none;
            }
            .el-collapse-item__header {
                border-bottom: 2px solid rgba(0, 0, 0, 0.06);
                height: 64px;
                &.is-active {
                    background: #F6F6F6;
                    border-bottom: 2px solid transparent;
                }
            }
            .el-collapse-item__wrap {
                border-bottom: 2px solid rgba(0, 0, 0, 0.06);
            }
            .el-collapse-item__content {
                padding: 0;
            }
            .el-collapse-item:last-child {
                .el-collapse-item__header, .el-collapse-item__wrap {
                    border-bottom: 2px solid transparent;
                }
            }
            .el-collapse-item__arrow {
                margin-right: 24px;
            }
            .el-collapse-item:nth-child(4) .option-percentage {
                background: #00117B;
            }

            .el-collapse-item:nth-child(3) .option-percentage {
                background: #2941D8;
            }

            .el-collapse-item:nth-child(2) .option-percentage {
                background: #586BDF;
            }

            .el-collapse-item:nth-child(1) .option-percentage {
                background: #9AA4E5;
            }

            .el-collapse-item.is-disabled .el-collapse-item__header {
                cursor: default;
            }

            &__header {
                padding: 14px 20px 14px 24px;
                height: 100%;
                width: calc(100% - 24px);
                display: flex;
                align-items: center;
                justify-content: space-between;

                .option-count {
                    background: #ECECEC;
                    border-radius: 3px;
                    padding: 3px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                }
                &--title {
                    display: flex;
                    align-items: center;
                    h4 {
                        font-weight: 800;
                        font-size: 16px;
                        line-height: 26px;
                        color: #515151;
                        margin-bottom: 0;
                        margin-left: 24px;
                    }
                }
            }

            &__data {
                .content-data-item {
                    display: flex;
                    align-items: center;
                    padding: 16px 24px;
                    &:not(:last-child) {
                        border-bottom: 1px solid #0000001A;
                    }
                    &__name {
                        margin-right: 16px;
                        img {
                            width: 39px;
                            height: 39px;
                            border-radius: 50%;
                            object-fit: cover;
                            margin-right: 12px;
                        }
                        span {
                            color: #515151;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 12.65px;
                            word-wrap: break-word
                        }
                    }
                    &__date {
                        display: flex;
                        justify-content: center;
                        min-width: 300px;
                        .job-title {
                            color: #969696;
                            font-size: 12px;
                            line-height: 158.023%;
                            padding-right: 16px;
                            border-right: 1px solid #E5E5E5;
                            margin-right: 16px;
                        }
                        .date-time-zone {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #969696;
                            margin-right: 16px;
                        }
                        .session-id {
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #2B2B2B;
                        }
                    }
                    &__bank {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-left: auto;
                        img {
                            width: 23px;
                            height: 23px;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                        span {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 158.02%;
                            color: #2B2B2B;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>
