import { BaseModel } from "@/store/models/base/BaseModel";
import { Constants } from "@/core/config/constants";
import { QuestionModel } from "@/store/models/bank/CompareProductAreaModel";
import CircleChartModel from "@/store/models/CircleChartModel";

interface statsDotChartDataInterface {
    date: string;
    gap: number;
    importance: number;
    score: number;
}

export class BankSpecificQuestionDataContainer {
    isCustom: boolean = false;
    question: QuestionModel;
    circleChartData: CircleChartModel | null = null;
    allCustomers: QuestionsCustomerModel[] = [];
    allComments: QuestionsCommentsModel[] = [];
    statsDotChartData: statsDotChartDataInterface[] = [];
    scoreSectionData: any = {
        customers: 0,
        respondents: 0,
        comments: 0,
    };

    constructor(obj, isCustom: boolean = false) {
        this.isCustom = isCustom;
        this.allCustomers = obj.customers ? obj.customers.map(cms => new QuestionsCustomerModel(cms)) : [];
        this.allComments = obj.comments ? obj.comments.map(cmt => new QuestionsCommentsModel(cmt)) : [];
        this.question = new QuestionModel( isCustom ? {...obj, ...obj.stats} : {...obj.question, ...obj.stats, comments: (obj.comments && obj.comments.length) ? obj.comments.length : 0});
        this.circleChartData = new CircleChartModel(obj.stats);
        this.scoreSectionData = {
            customers: this.allCustomers.length,
            respondents: obj.stats ? (Number(obj.stats.respondents) || 0) : 0,
            comments: obj.comments ? (obj.comments.length || 0) : 0,
        };

        if (isCustom) {
            this.statsDotChartData = (obj && obj.dot_chart) ? obj.dot_chart : [];
        } else {
            this.statsDotChartData = (obj && obj.stats && obj.stats.dotChart) ? obj.stats.dotChart : [];
        }
    }

    async sortCommentsBy(what: string | null): Promise<QuestionsCommentsModel[]> {
        switch (what) {
            case 'highest_score':
                this.allComments = this.allComments.sort((q1: QuestionsCommentsModel, q2: QuestionsCommentsModel) => q2.score - q1.score);
                break;
            case 'lowest_score':
                this.allComments = this.allComments.sort((q1: QuestionsCommentsModel, q2: QuestionsCommentsModel) => q1.score - q2.score);
                break;
            case 'highest_importance_score':
                this.allComments = this.allComments.sort((q1: QuestionsCommentsModel, q2: QuestionsCommentsModel) => q2.importance - q1.importance);
                break;
            case 'lowest_importance_score':
                this.allComments = this.allComments.sort((q1: QuestionsCommentsModel, q2: QuestionsCommentsModel) => q1.importance - q2.importance);
                break;
            case 'highest_negative_gap':
                this.allComments = this.allComments.sort((q1: QuestionsCommentsModel, q2: QuestionsCommentsModel) => q1.gap - q2.gap);
                break;
            case 'highest_positive_gap':
                this.allComments = this.allComments.sort((q1: QuestionsCommentsModel, q2: QuestionsCommentsModel) => q2.gap - q1.gap);
                break;
            case 'highest_standard_deviation':
                this.allComments = this.allComments.sort((q1: QuestionsCommentsModel, q2: QuestionsCommentsModel) => q1.score - q2.score);
                break;
            case 'lowest_standard_deviation':
                this.allComments = this.allComments.sort((q1: QuestionsCommentsModel, q2: QuestionsCommentsModel) => q1.score - q2.score);
                break;
        }
        return this.allComments;
    }
}

class QuestionsCustomerModel extends BaseModel {
    comments_count: number;
    icon_path: any;
    name: string;
    score: number;
    importance: number;
    collapsed: boolean = false;
    users: QuestionsCustomerUsersModel[];

    constructor(obj: any) {
        super(obj);

        this.comments_count = obj['comments_count'];
        this.icon_path = obj['icon_path'] || Constants.BUSINESS_TMP_LOGO;
        this.name = obj['name'];
        this.score = obj['score'];
        this.importance = obj['importance'];
        this.users = obj.users ? obj.users.map((user: any) => new QuestionsCustomerUsersModel(user)) : [];
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }
}

class QuestionsCustomerUsersModel extends BaseModel {
    first_name: string;
    last_name: string;
    job_title: string;
    icon_path: string;
    score: number;
    importance: number;
    gap: number;

    constructor(obj: any) {
        super(obj);

        this.first_name = obj.first_name;
        this.last_name = obj.last_name;
        this.job_title = obj.job_title;
        this.icon_path = obj.icon_path || Constants.USER_TMP_LOGO;
        this.score = obj.score || 0;
        this.importance = obj.importance || 0;
        this.gap = obj.gap || 0;
    }
}

class QuestionsCommentsModel {
    business_icon_path: any;
    business_id: number;
    business_name: string;
    comment: string;
    date: string;
    fullname: string;
    gap: number;
    importance: number;
    job_title: string;
    score: number;
    user_icon_path: string;
    country: string;

    constructor(obj) {
        this.business_icon_path = obj['business'].icon_path || Constants.BUSINESS_TMP_LOGO;
        this.business_id = obj['business'].id;
        this.business_name = obj['business'].name;
        this.comment = obj['comment_object'].comment;
        this.country = obj['comment_object'].country;
        this.date = obj['comment_object'].created_at;
        this.fullname = obj['commenter'] ? `${obj['commenter'].first_name} ${obj['commenter'].last_name}` : '';
        this.gap = obj['stats'].gap;
        this.importance = obj['stats'].importance;
        this.job_title = obj['commenter'].job_title;
        this.score = obj['stats'].score;
        this.user_icon_path = obj['commenter'].icon_path || Constants.USER_TMP_LOGO;
    }
}
