import {
    SpecificCustomQuestionBaseContainer
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionBaseContainer";
import { UserModelMin } from "@/store/models/shared/UserModelMin";
import { BankModelMin } from "@/store/models/bank/BankModelMin";

interface chartDataInterface {
    series: chartDataSeriesInterface[];
    categories: string[];
}

interface chartDataSeriesInterface {
    name: string;
    data: number[]
}

export class SpecificCustomQuestionMultiChoiceGrid extends SpecificCustomQuestionBaseContainer {
    isNoAnswerFound: boolean = false;
    activeTabName: string = '';
    chartData: chartDataInterface = {
        series: [],
        categories: []
    };
    respondentsDetails: RespondentsDetailsMultiChoiceGridModel[] = [];

    constructor(obj) {
        super(obj);

        this.setDataMultiChoiceGrid(obj);
    }

    setDataMultiChoiceGrid(data) {
        if (data.stats && data.stats.length) {
            this.isNoAnswerFound = false;
            data.stats.forEach(stat => {
                this.chartData.categories.push(stat.row);

                for (let j = 0; j < stat.answers.length; j ++) {
                    let opt = stat.answers[j];

                    if(!this.chartData.series[j]) {
                        this.chartData.series[j] = {
                            name: opt.column,
                            data: [opt.percentage]
                        }
                    } else {
                        this.chartData.series[j].data.push(opt.percentage)
                    }
                }

                let respondentsDetailsItem = new RespondentsDetailsMultiChoiceGridModel(stat);
                this.respondentsDetails.push(respondentsDetailsItem);
            })
        } else {
            this.isNoAnswerFound = true;
            data.rows.forEach(row => {
                this.chartData.categories.push(row.label);
                let respondentsDetailsItem = new RespondentsDetailsMultiChoiceGridModel({
                    ...row,
                    answers: data.columns
                });
                this.respondentsDetails.push(respondentsDetailsItem);
            })
            this.chartData.series = data.columns.map(col => {
                return {
                    name: col.label,
                    data: []
                }
            })
        }

        this.activeTabName = this.respondentsDetails[0].rowName;
    }
}

class RespondentsDetailsMultiChoiceGridModel {
    rowName: string;
    columnsData: RespondentsDetailsMultiChoiceGridColumnModel[];
    overallAnswersCount: number;

    constructor(data) {
        this.rowName = data.row || data.label;
        this.overallAnswersCount = data.overallAnswersCount || 0;
        this.columnsData = data.answers.map(an => new RespondentsDetailsMultiChoiceGridColumnModel(an));
    }
}

class RespondentsDetailsMultiChoiceGridColumnModel {
    title: string;
    percentage: number;
    respondentsCount: number;
    answersData: any[];

    constructor(data) {
        this.title = data.column || data.label;
        this.percentage = data.percentage | 0;
        this.respondentsCount = data.answers ? data.answers.length : 0;
        this.answersData = data.answers ? data.answers?.map(an => new RespondentsDetailsMultiChoiceGridAnswerModel(an)) : [];
    }
}

class RespondentsDetailsMultiChoiceGridAnswerModel {
    surveyId: number;
    user: UserModelMin;
    bank: BankModelMin;
    date: Date;

    constructor(data) {
        this.surveyId = data.survey_id;
        this.date = data.created_at;
        this.user = new UserModelMin(data.user);
        this.bank = new BankModelMin(data.bank);
    }
}
