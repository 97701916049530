import {
    SpecificCustomQuestionBaseContainer
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionBaseContainer";
import { UserModelMin } from "@/store/models/shared/UserModelMin";
import { BankModelMin } from "@/store/models/bank/BankModelMin";

export class SpecificCustomQuestionTextOnly extends SpecificCustomQuestionBaseContainer {
    allAnswers: any[] = [];
    pagination: any = {
        totalItems: 0,
        page: 1,
        pageSize: 5,
    };

    constructor(obj) {
        super(obj);

        this.setDataTextOnly(obj);
    }

    setDataTextOnly(data) {
        if (data.stats && data.stats.length) {
            this.allAnswers = data.stats.map(st => new TextOnlyAnswerModel(st));
            this.pagination.totalItems = data.stats.length;
        }
    }

    get displayData() {
        return this.allAnswers.slice(this.pagination.pageSize * this.pagination.page - this.pagination.pageSize, this.pagination.pageSize * this.pagination.page);
    }
}

class TextOnlyAnswerModel {
    surveyId: number;
    user: UserModelMin;
    bank: BankModelMin;
    date: Date;
    answerText: string;

    constructor(data) {
        this.surveyId = data.survey_id;
        this.date = data.answer.created_at;
        this.user = new UserModelMin(data.user);
        this.bank = new BankModelMin(data.bank);
        this.answerText = data.answer.answer_text;
    }
}
