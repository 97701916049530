<template>
    <div class="bank-page-content-score-importance">
        <CommentItemInfo
            :questionsData="pageContainer.question"
            :isCustom="pageContainer.isCustom"
            @scrollCommentSection="scrollCommentSection"
        />
        <CommentItemAnalytics
            :pageContainer="pageContainer"
        />
        <CommentItemCustomersRespondents
            :pageContainer="pageContainer"
        />
        <div class="card" ref="commentSection">
            <div class="row custom-row">
                <div class="col-lg-5 comment-item-customers">
                    <CommentItemCustomers
                        :customers="pageContainer.allCustomers"
                    />
                </div>
                <div class="col-lg-7">
                    <CommentItemCustomersSelected
                        :pageContainer="pageContainer"
                        :allComments="pageContainer.allComments"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CommentItemInfo from "@/buying-teams/pages/bank/dashboard/sections/CommentItemInfo";
import CommentItemAnalytics from "@/buying-teams/pages/bank/dashboard/sections/CommentItemAnalytics";
import CommentItemCustomersRespondents
    from "@/buying-teams/pages/bank/dashboard/sections/CommentItemCustomersRespondents";
import CommentItemCustomers from "@/buying-teams/pages/bank/dashboard/sections/CommentItemCustomers";
import CommentItemCustomersSelected from "@/buying-teams/pages/bank/dashboard/sections/CommentItemCustomersSelected";
import { BankSpecificQuestionDataContainer } from "@/store/models/bank/BankSpecificQuestionDataContainer";

export default {
    name: "BankPageContentScoreImportance",
    components: {
        CommentItemCustomersRespondents,
        CommentItemAnalytics,
        CommentItemInfo,
        CommentItemCustomers,
        CommentItemCustomersSelected
    },
    props: {
        pageContainer: {
            type: [Object, BankSpecificQuestionDataContainer]
        }
    },
    methods: {
        scrollCommentSection() {
            const commentSection = this.$refs.commentSection;
            if (!commentSection) return;
            commentSection.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }
}
</script>

<style scoped lang="scss">
.bank-page-content-score-importance {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>
