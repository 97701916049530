<template>
    <div class="comment-item platform-dashboard-pages">
        <template v-if="!filterLoading">
            <a class="back-to-btn mb-0" href="" @click.prevent="handleBackPrevPage">
                <img src="/media/buying/icons/arrow-left.svg" alt="">
                {{ $t("Back to [Previous Screen]", { previousScreen: 'Previous Screen' }) }}
            </a>

<!--            <el-select-->
<!--                v-model="question"-->
<!--                class="dedicated-field-state mt-0"-->
<!--                :disabled="loading"-->
<!--                @change="handleChangedQuestion"-->
<!--            >-->
<!--                <el-option-->
<!--                    v-for="(item, index) in questionsList"-->
<!--                    :key="index"-->
<!--                    :label="item"-->
<!--                    :value="item"-->
<!--                />-->
<!--            </el-select>-->

            <BankDedicatedQuestionFilters
                ref="bankDedicatedQuestionFilters"
                @apply-filters="applyDashboardFilters"
            />
        </template>

        <CustomLoader v-if="loading" height="400px"/>

        <template v-else>
            <!--Score & Importance -->
            <BankPageContentScoreImportance
                v-if="questionType === FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE"
                :page-container="questionsCustomersContainer"
            />

            <!-- Multiple Choice -->
            <PageContentMultipleChoice
                v-if="questionType === FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE"
                class="mt-9"
                :page-container="questionsCustomersContainer"
            />

            <!-- Text Only -->
            <PageContentTextOnly
                v-if="questionType === FeedbackSessionQuestionsTypeEnum.TEXT_ONLY"
                class="mt-9"
                :page-container="questionsCustomersContainer"
            />

            <!-- Multi Choice Grid -->
            <PageContentMultiChoiceGrid
                v-if="questionType === FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID"
                class="mt-9"
                :page-container="questionsCustomersContainer"
            />
        </template>
    </div>
</template>

<script>

import store from "@/store";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { BankSpecificQuestionDataContainer } from "@/store/models/bank/BankSpecificQuestionDataContainer";
import BankPageContentScoreImportance
    from "@/buying-teams/pages/bank/dashboard/components/BankPageContentScoreImportance";
import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";
import {
    SpecificCustomQuestionTextOnly
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionTextOnly";
import {
    SpecificCustomQuestionMultipleChoice
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionMultipleChoice";
import {
    SpecificCustomQuestionMultiChoiceGrid
} from "@/store/models/shared/specific-custom-questions/SpecificCustomQuestionMultiChoiceGrid";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import PageContentMultipleChoice from "@/buying-teams/pages/business/question/components/PageContentMultipleChoice";
import PageContentTextOnly from "@/buying-teams/pages/business/question/components/PageContentTextOnly";
import PageContentMultiChoiceGrid from "@/buying-teams/pages/business/question/components/PageContentMultiChoiceGrid";
import BankDedicatedQuestionFilters from "@/buying-teams/pages/bank/dashboard/components/BankDedicatedQuestionFilters";

export default {
    name: "BankDedicatedQuestion",
    components: {
        BankDedicatedQuestionFilters,
        PageContentMultiChoiceGrid,
        PageContentTextOnly,
        PageContentMultipleChoice,
        BankPageContentScoreImportance,
        CustomLoader,
    },
    data() {
        return {
            loading: true,
            selectedCustomers: [],
            questionsCustomersContainer: null,
            filterLoading: true,
            isCustom: null,
            surveyId: null,
            questionType: '',
            FeedbackSessionQuestionsTypeEnum,
            question: '',
        };
    },
    methods: {
        async applyDashboardFilters(filters) {
            await this.fetchDashboardData(filters);
        },

        async fetchDashboardData(filter) {
            this.loading = true;
            return await store.dispatch('getSingleQuestion', {
                question_id: +this.$route.params.id,
                filter,
                is_custom: this.isCustom
            }).then((res) => {
                    if (this.isCustom) {
                        this.questionType = res.type;
                        const data = { ...res, ...res.question };

                        switch (this.questionType) {
                            case FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE:
                                this.questionsCustomersContainer = new BankSpecificQuestionDataContainer(res, true);
                                break;
                            case FeedbackSessionQuestionsTypeEnum.TEXT_ONLY:
                                this.questionsCustomersContainer = new SpecificCustomQuestionTextOnly(data);
                                break;
                            case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE:
                                this.questionsCustomersContainer = new SpecificCustomQuestionMultipleChoice(data);
                                break;
                            case FeedbackSessionQuestionsTypeEnum.MULTIPLE_CHOICE_GRID:
                                this.questionsCustomersContainer = new SpecificCustomQuestionMultiChoiceGrid(data);
                                break;
                        }
                    } else {
                        this.questionType = FeedbackSessionQuestionsTypeEnum.SCORE_IMPORTANCE;
                        this.questionsCustomersContainer = new BankSpecificQuestionDataContainer(res);
                    }
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        handleBackPrevPage() {
            this.$router.go(-1);
        },
        async initPageData() {
            await store.dispatch('initFiltersData', {
                type: BankFiltersTypeEnum.SINGLE_QUESTION,
                payload: {question_id: +this.$route.params.id}
            })
            this.filterLoading = false;

            await this.fetchDashboardData(this.singleQuestionFilterData);
        },
        handleChangedQuestion() {

        }
    },
    computed: {
        userInfo() {
            return store.getters.currentUser;
        },
        bank() {
            return store.getters.bank;
        },
        singleQuestionFilterData() {
            return store.getters.getBankSingleQuestionFilterData;
        },
        questionsList() {
            return [
                'Our Account Receivables needs are well covered',
                'Our Account Receivables needs are well covered 2',
            ]
        }
    },
    async mounted() {
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });

        this.initPageData();
    },
    beforeMount() {
        this.isCustom = this.$route.query.isCustom || null;
        this.surveyId = this.$route.query.surveyId || null;
    },
};
</script>

<style lang="scss">

.comment-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    .custom-row {
        --bs-gutter-x: 2rem;
        @media (max-width: 1260px) {
            flex-direction: column;
            & > div {
                width: 100%;
            }
        }
    }
    .question-page-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;
    }
    .product-area-select {
        .el-input__inner {
            padding-left: 10px;
            height: 33px;
            border-bottom: 2px solid #363636;
            border-radius: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 13px;
            color: rgba(0, 0, 0, 0.87);
            width: 276px;
        }

        .el-input__suffix svg path {
            fill: #000000;
        }
    }
}

.dashboard-section {
    &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: rgba(67, 67, 67, 0.6);
        padding-left: 9px;
        margin-bottom: 9px;
    }
}

.comment-item-customers {
    position: relative;
    padding-right: 0;

    &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        top: 74px;
        width: 1px;
        background: #F6F6F6;
    }
}
</style>
