import { FeedbackSessionQuestionsTypeEnum } from "@/store/enums/FeedbackSessionQuestionsTypeEnum";

export class SpecificCustomQuestionBaseContainer {
    isCustom: boolean = true;
    question: SingleCustomQuestionBaseModel;

    constructor(obj) {
        this.question = new SingleCustomQuestionBaseModel(obj);
    }
}

class SingleCustomQuestionBaseModel {
    question_id: number;
    type: FeedbackSessionQuestionsTypeEnum;
    question: string;
    productArea: string;

    constructor(obj) {
        this.question_id = obj.id;
        this.type = obj.type;
        this.question = obj.text || obj.question;
        this.productArea = obj.product_area;
    }
}
